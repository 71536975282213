<template>
  <el-card class="mian-container">
    <!-- 标题 -->
    <div slot="header">
      {{ title }}
    </div>
    <!-- 树 -->
    <div>
      <el-tree node-key="id"
        :data="treeData"
        @node-click="handleNodeClick"
        @node-expand="handleNodeClick"
        :show-checkbox="showSheckbox"
        :props="defaultProps"
        :highlight-current="true"
        :default-expanded-keys="defaultNodes">
        <div slot-scope="{ node, data }">
          <i v-if="data.parentId == 0"
            class="el-icon-folder-opened"></i>{{ node.label }}
        </div>
      </el-tree>
    </div>
  </el-card>
</template>

<script>
export default {
  name: "OrganizationTree",
  props: {
    title: {
      type: String,
      default: "",
    },
    treeData: {
      type: Array,
      default: () => [],
    },
    showSheckbox: {
      type: Boolean,
      default: false,
    },
    defaultProps: {
      type: Object,
      default: () => {
        return {
          children: "children",
          label: "label",
        };
      },
    },
    selectTreeNode: {
      type: Object,
      default: () => { },
    },
  },
  data() {
    return {};
  },
  computed: {
    defaultNodes() {
      if (this.treeData.length) {
        return [this.treeData[0].id];
      } else {
        return [];
      }
    },
  },
  methods: {
    handleNodeClick(data) {
      this.$emit("update:selectTreeNode", data);
      this.$emit("nodeClick", data);
    },
  },
};
</script>

<style lang="scss" scoped>
.mian-container {
  height: 800px;
}
.el-icon-folder-opened {
  color: #4278c9ff;
}
</style>
