<template>
  <el-dialog
    :visible.sync="visible"
    :close-on-click-modal="false"
    width="1000px"
  >
    <div class="title" slot="title">部门成员</div>
    <el-table :data="tableData" height="400" fit border>
      <el-table-column
        v-for="column in columns"
        :key="column.prop"
        :prop="column.prop"
        :label="column.label"
        :width="column.width"
      >
      </el-table-column>
      <el-table-column label="操作" width="100" fixed="right">
        <template slot-scope="{ row }">
          <el-button type="text" @click="removeStaff(row)"
            >移出本部门</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="searchData.pageNum"
      :page-size="searchData.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="totalNum"
    ></el-pagination>
  </el-dialog>
</template>

<script>
export default {
  name: "StaffBoxDialog",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    tableData: {
      type: Array,
      default: () => [],
    },
    totalNum: {
      type: Number,
      default: 0,
    },
    deptId: {
      type: String,
      default: "",
    },
  },
  watch: {
    visible(n) {
      this.$emit("update:show", n);
    },
    show(n) {
      this.visible = n;
    },
  },
  data() {
    return {
      visible: false,
      searchData: {
        pageNum: 1,
        pageSize: 10,
      },
      columns: [
        {
          label: "姓名",
          prop: "name",
          width: 200,
        },
        {
          label: "手机号码",
          prop: "phone",
          width: 200,
        },
        {
          label: "邮箱",
          prop: "email",
          width: 200,
        },
        {
          label: "岗位",
          prop: "postName",
          width: 100,
        },
        {
          label: "操作人",
          prop: "updateBy",
          width: 100,
        },
        {
          label: "操作时间",
          prop: "updateTime",
          width: 200,
        },
      ],
    };
  },
  methods: {
    removeStaff(row) {
      if (!row.deptId) {
        this.$message({
          type: "error",
          message: "无法移除！",
        });
        return;
      }
      this.$confirm(`确认删除该成员？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let deptIds = row.deptId.split(",");
        let rmIndex = deptIds.findIndex((id) => {
          return id == this.deptId;
        });
        deptIds.splice(rmIndex, 1);
        deptIds = deptIds.toString();
        this.$emit("removeStaff", deptIds, row.userId, this.searchData);
      });
    },
    emitData() {
      this.$emit(
        "getStaffList",
        { id: this.deptId },
        this.searchData.pageNum,
        this.searchData.pageSize
      );
    },
    handleSizeChange(val) {
      this.searchData.pageSize = val;
      this.emitData();
    },
    handleCurrentChange(val) {
      this.searchData.pageNum = val;
      this.emitData();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__header {
  background-color: #4b7bca;
  border-radius: 10px 10px 0 0;
}
::v-deep .el-dialog {
  border-radius: 10px;
}
::v-deep .el-select {
  width: 100%;
}
.title {
  color: #ffffff;
}
</style>
