<template>
  <el-dialog
    :visible.sync="visible"
    @close="clearClose"
    @open="fillData"
    destroy-on-close
    :close-on-click-modal="false"
    width="500px"
  >
    <div class="title" slot="title">
      {{ titleName }}
    </div>
    <div slot="footer">
      <el-button @click="confirm">确定</el-button>
      <el-button @click="reset">重置</el-button>
    </div>
    <el-form
      :model="form"
      :rules="rules"
      ref="form"
      label-position="right"
      label-width="100px"
      size="mini"
    >
      <el-form-item
        v-if="selectTreeData.length"
        label="上级部门："
        prop="parentId"
        required
      >
        <el-select v-model="form.parentId" value-key="id" clearable>
          <!-- 占位用 -->
          <el-option
            style="display: none"
            v-for="item in parentOption"
            :key="item.id"
            :label="item.label"
            :value="item.id"
          ></el-option>

          <el-tree
            ref="orgTree"
            :data="selectTreeData"
            node-key="id"
            :props="defaultProps"
          >
            <div slot-scope="{ node, data }" @click="selectNode(data)">
              <span>{{ node.label }}</span>
            </div>
          </el-tree>
        </el-select>
      </el-form-item>
      <el-form-item v-else label="上级部门：">
        <el-select
          v-model="form.parentId"
          placeholder="没有上级部门无需选择"
          disabled
        >
        </el-select>
      </el-form-item>
      <el-form-item label="部门名称：" prop="deptName" required>
        <el-input v-model="form.deptName" clearable></el-input>
      </el-form-item>
      <el-form-item label="负责人：" prop="charge">
        <el-input v-model="form.charge" clearable></el-input>
      </el-form-item>
      <el-form-item label="备注：" prop="remark">
        <el-input
          v-model="form.remark"
          type="textarea"
          :rows="2"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="年度目标：" prop="annualTarget">
        <el-input
          v-model="form.annualTarget"
          type="number"
          clearable
        ></el-input>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
export default {
  name: "AddOrEditDialog",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    actionType: {
      type: String,
      default: "add",
    },
    actionTypeEmun: {
      type: Object,
      default: () => {
        return {
          add: "添加",
          edit: "编辑",
        };
      },
    },
    selectTreeData: {
      type: Array,
      default: () => [],
    },
    inputData: {
      type: Object,
      default: () => null,
    },
    defaultProps: {
      type: Object,
      default: () => {
        return {
          children: "children",
          label: "label",
          disabled: "disabled",
        };
      },
    },
    defaultNode: {
      type: Object,
      default: () => {
        return {
          id: null,
          label: null,
        };
      },
    },
  },
  data() {
    return {
      visible: false,
      topLevelId: 0,
      topLevelTips: "没有上级部门！",
      parentOption: [{ id: null, label: null }],
      form: {
        parentId: "",
        deptName: "",
        charge: "",
        remark: "",
        annualTarget: "",
      },
      rules: {
        parentId: [
          { required: true, message: "请选择上级部门", trigger: "change" },
        ],
        deptName: [
          { required: true, message: "请输入部门名称", trigger: "blur" },
          { required: true, message: "请输入部门名称", trigger: "change" },
          {
            min: 0,
            max: 24,
            message: "长度在 0 到 24 个字符",
            trigger: "change",
          },
        ],
        charge: [
          { required: true, message: "请输入部门负责人", trigger: "blur" },
          { required: true, message: "请输入部门负责人", trigger: "change" },
          {
            min: 0,
            max: 24,
            message: "长度在 0 到 24 个字符",
            trigger: "change",
          },
        ],
      },
    };
  },
  watch: {
    visible(n) {
      this.$emit("update:show", n);
    },
    show(n) {
      this.visible = n;
    },
  },

  mounted() {},
  computed: {
    titleName() {
      return this.actionTypeEmun[this.actionType];
    },
  },
  methods: {
    selectNode(data) {
      if (this.actionType == "edit") {
        if (this.form.id == data.id) {
          this.$message({
            type: "warning",
            message: "不可设置自己为自己上级！",
          });
          return;
        }
      }
      this.parentOption = [];
      this.parentOption.push({
        id: data.id,
        label: data[this.defaultProps.label],
      });
      this.form.parentId = data.id;
    },
    reset() {
      this.parentOption = [{ id: null, label: null }];
      if (this.inputData && this.actionType == "edit") {
        this.parentOption.push({
          id: this.inputData.parentId,
          label:
            this.inputData.parentId == this.topLevelId
              ? this.topLevelTips
              : this.inputData.parentName,
        });
        this.form = Object.assign({}, this.inputData);
      } else {
        this.clearClose();
      }
    },
    confirm() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          let emitData = this.form;
          if (this.actionType == "edit") {
            emitData = {
              ...this.form,
              id: this.inputData.id,
            };
          }
          this.$emit("confirm", emitData);
          this.visible = false;
        }
      });
    },
    clearClose() {
      this.parentOption = [{ id: null, label: null }];
      //this.$refs.form.resetFields();
      Object.keys(this.form).forEach((key) => {
        this.form[key] = "";
      });
    },
    fillData() {
      this.parentOption = [{ id: null, label: null }];
      if (this.actionType == "edit") {
        if (this.inputData) {
          if (this.inputData.parentId == this.topLevelId) {
            this.parentOption.push({
              id: this.inputData.parentId,
              label: this.topLevelTips,
            });
          } else {
            this.parentOption.push({
              id: this.inputData.parentId,
              label: this.inputData.parentName,
            });
          }
          this.form = Object.assign({}, this.inputData);
        }
      } else {
        this.parentOption.push({
          id: this.defaultNode.id,
          label: this.defaultNode[this.defaultProps.label],
        });
        this.form.parentId = this.defaultNode.id;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__header {
  background-color: #4b7bca;
  border-radius: 10px 10px 0 0;
}
::v-deep .el-dialog {
  border-radius: 10px;
}
::v-deep .el-select {
  width: 100%;
}
.title {
  color: #ffffff;
}
</style>
