<template>
  <div class="organizational-structure">
    <el-container>
      <el-aside class="aside"
        width="220px">
        <OrganizationTree title="组织目录"
          :treeData="asideTree"
          :defaultProps="treeProps"
          :selectTreeNode.sync="currentTableNode"
          @nodeClick="asideNodeClick($event)"></OrganizationTree>
      </el-aside>
      <el-main>
        <GeneralTable ref="GeneralTable"
          :column="column"
          :option="option"
          :dataList="tableRecordList"
          :totalNum="totalNum"
          @search-change="initData"
          @add-change="addOrEdit('add')"
          @del-change="deletDepart"
          @export-data="exportData"
          tableName="OrganizationalStructure">
          <template slot="operate"
            slot-scope="{ item }">
            <el-button class="polecy-button"
              type="text"
              size="small"
              v-if="handleHasPerms('M22-architecture-edit')"
              @click="relateUser(item)">关联用户<el-divider direction="vertical"></el-divider>
            </el-button>
            <el-button @click="addOrEdit('edit', item)"
              size="small"
              type="text"
              v-if="handleHasPerms('M22-architecture-edit')">编辑<el-divider direction="vertical"></el-divider>
            </el-button>
            <el-button type="text"
              size="small"
              v-if="handleHasPerms('M22-architecture-edit')"
              @click="deletDepart([item])">删除</el-button>
          </template>
          <template slot="count"
            slot-scope="{ item }">
            <el-button type="text"
              @click="getStaffList(item)">{{ item.count }}</el-button>
          </template>
        </GeneralTable>
      </el-main>
    </el-container>
    <!-- 关联用户弹框 -->
    <AssociatingUsers :treeDialogVisible.sync="showTreeDialog"
      :treeData="relateTree"
      :treeSelectNodeList="treeSelectNodeList"
      :defaultProps="treeProps"
      :topPickerData="topPickerData"
      :dataId="dept.id"
      @submitData="submitRelateUser"></AssociatingUsers>
    <!-- 添加编辑弹框 -->
    <AddOrEditDialog :show.sync="showAddEditDialog"
      :actionType="actionType"
      :selectTreeData="asideTree"
      :defaultProps="treeProps"
      :defaultNode="currentTableNode"
      :inputData="inputData"
      @confirm="addOrEditSubmit"></AddOrEditDialog>
    <!-- 成员弹框 -->
    <StaffBoxDialog :show.sync="showStaffDialog"
      :tableData="staffData"
      :totalNum="staffTotalNum"
      :deptId="deptId"
      @getStaffList="getStaffList"
      @removeStaff="removeStaff"></StaffBoxDialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import AssociatingUsers from "@/components/SystemManagement/DialogBox/AssociatingUsers/index.vue";
import OrganizationTree from "@/components/SystemManagement/common/OrganizationTree/index.vue";
import GeneralTable from "@/components/GeneralTable/index.vue";
import AddOrEditDialog from "./components/AddOrEditDialog.vue";
import StaffBoxDialog from "./components/StaffBoxDialog.vue";
import {
  getOrganizationTree,
  getUnderDepartments,
  getStaffByDepartById,
  deletDepartByIds,
  addOrEditDepart,
  exportDepartData,
  getRelateTreeData,
  addRelateUser,
  editUser,
} from "@/api/SystemData";
import { hasPerms } from "@/utils/auth";
export default {
  name: "OrganizationalStructure",
  components: {
    AssociatingUsers,
    OrganizationTree,
    GeneralTable,
    AddOrEditDialog,
    StaffBoxDialog,
  },
  data() {
    return {
      showTreeDialog: false,
      showAddEditDialog: false,
      showStaffDialog: false,
      actionType: "add",
      totalNum: 0,
      staffTotalNum: 0,
      dept: {},
      topPickerData: [],
      deptId: "",
      treeProps: {
        children: "children",
        label: "title",
        disabled: "selected",
        //员工标识
        type: 2,
        //标识名
        typeName: "type",
        uuidName: "value",
      },
      staffData: [],
      //左侧树节点当前选择的节点
      currentTableNode: {},
      tableRecordList: [],
      option: {
        isAdd: hasPerms('M22-architecture-add'), //添加 事件 @add-change
        isDel: hasPerms('M22-architecture-edit'), //删除 @del-change
        isEdit: hasPerms('M22-architecture-edit'), // 编辑 @edit-change
        isExport: hasPerms("M22-architecture-edit"),
        isSearch: true, // 搜索 @search-change
        isRefresh: true, // 刷新 @search-change
        pageSize: 10,
        searchList: [
          {
            label: "部门名称",
            prop: "deptName",
            formType: "input",
            maxLength: 36,
            clearable: true,
            placeholder: "请输入部门名称",
          },
          {
            label: "负责人",
            prop: "charge",
            formType: "input",
            maxLength: 36,
            clearable: true,
            placeholder: "请输入负责人",
          },
          {
            label: "创建时间",
            formType: "daterange",
            clearable: true,
            format: 'yyyy.MM.dd',
            prop: ["begin", "end"],
          },
        ],
      },
      column: [
        {
          label: "ID",
          type: "index",
          isShow: true,
          fixed: "left",
        },
        {
          label: "租户名称",
          prop: "tenantName",
          isPermissions: this.$store.state.tenantId === '000000',
          isShow: true,
        },
        {
          label: "部门名称",
          prop: "deptName",
          isShow: true,
        },
        {
          label: "上级部门",
          prop: "parentName",
          isShow: true,
        },
        {
          label: "负责人",
          prop: "charge",
          isShow: true,
        },
        {
          label: "部门成员",
          prop: "count",
          isShow: true,
          isSlot: true,
        },
        {
          label: "备注",
          prop: "remark",
          isShow: true,
        },
        {
          label: "创建时间",
          prop: "createTime",
          isShow: true,
        },
        {
          label: "更新时间",
          prop: "updateTime",
          isShow: true,
        },
        {
          label: "操作",
          prop: "operate",
          isShow: true,
          width: 220,
          fixed: "right",
          isSlot: true,
        },
      ],
      asideTree: [],
      relateTree: [],
      treeSelectNodeList: [],
      inputData: {
        parentId: "",
        deptName: "",
        charge: "",
        remark: "",
      },
    };
  },
  computed: {
    ...mapState(['tenantId'])
  },
  mounted() {
    if (this.asideTree.length) {
      this.currentTableNode = { id: this.asideTree[0].id };
    }
  },
  methods: {
    initData(data = {}, current = 1, size = 10, searchType = 'top') {
      this.getAsideTree(data, current, size);
      this.asideNodeClick(data, current, size, searchType);
    },
    async getAsideTree(data, current = 1, size = 10) {
      let treeResult = await getOrganizationTree({
        ...data,
        current,
        size,
        //tenantId: this.tenantId=='000000' ?'':this.tenantId
      });
      if (treeResult.code === 200) {
        this.asideTree = treeResult.data;
      }
    },
    addOrEdit(type, data = null) {
      this.actionType = type;
      this.showAddEditDialog = true;
      this.inputData = data;
    },
    async relateUser(data) {
      this.dept = data;
      let result = await getRelateTreeData({
        deptId: data.id,
        tenantId: data.tenantId
      });
      if (result.code === 200 && result.data.deptVOS.length) {
        this.relateTree = result.data.deptVOS;
        this.treeSelectNodeList = result.data.userIds;
        this.topPickerData = [{ label: result.data.tenantName, value: 0 }];
        this.showTreeDialog = true;
      } else {
        this.$message({
          type: "warning",
          message: "获取数据失败！",
        });
      }
    },
    async asideNodeClick(data, current = 1, size = 10, searchType) {
      // this.currentTableNode = { id: this.asideTree[0].id };
      let { deptName, charge, begin, end, keyword } = data;
      let submitData = {};
      if (searchType == 'like') {
        submitData = {
          deptName: keyword,
          charge: keyword,
          tenantName: keyword,
          current,
          size,
          targetParam: this.currentTableNode.id,
          tenantId: this.tenantId,
        };
      } else {
        submitData = {
          deptName,
          charge,
          begin,
          end,
          current,
          size,
          targetParam: this.currentTableNode.id,
          tenantId: this.tenantId,
        };
      }
      let nodeResult = await getUnderDepartments(submitData);
      if (nodeResult.code === 200) {
        this.tableRecordList = nodeResult.data.records;
        this.totalNum = nodeResult.data.total || 0;
      } else {
        this.tableRecordList = {};
      }
    },
    async getStaffList(data, current = 1, size = 10) {
      this.deptId = data.id;
      let result = await getStaffByDepartById({
        deptId: data.id,
        current,
        size,
      });
      if (result.code === 200) {
        this.staffData = result.data.records;
        this.staffTotalNum = result.data.total || 0;
        this.showStaffDialog = true;
      } else {
        this.$message({
          type: "warning",
          message: "获取数据失败！",
        });
      }
    },
    deletDepart(data) {
      console.log(this.currentTableNode);
      if (!data.length) {
        this.$message({
          type: "warning",
          message: "没有删除项！",
        });
        return;
      }
      this.$confirm(`确认删除该组织？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        let ids = [];
        let canDelete = true;
        ids = data.map((item) => {
          if (item.count) {
            canDelete = false;
          }
          return item.id;
        });
        if (canDelete) {
          let result = await deletDepartByIds(ids);
          if (result.code != 200) {
            this.$message({
              type: "warning",
              message: result.msg,
            });
            return false;
          }
          this.$refs.GeneralTable.getList();
        } else {
          this.$message({
            type: "error",
            message:
              "存在关联的部门（岗位 or 用户），请移除后，再进行删除操作!",
          });
        }
      });
    },
    async addOrEditSubmit(data) {
      let result = await addOrEditDepart(data);
      if (result.code == 200) {
        this.initData({});
      }
    },
    exportData(data, obj) {
      // if (!data || !data.length) {
      //   this.$message.warning('请选择需要导出的组织架构！');
      //   return false;
      // }
      exportDepartData(obj);
    },
    async submitRelateUser(nodes) {
      let ids = nodes.map((item) => {
        return item.id;
      });
      let result = await addRelateUser({
        userIds: ids,
        deptId: this.dept.id,
      });
      if (result.code == 200) {
        this.$message({
          type: "success",
          message: "添加成功！",
        });
        this.$refs.GeneralTable.getList();
      }
    },
    async removeStaff(deptIds, staffId, { pageNum, pageSize }) {
      let result = await editUser({
        id: staffId,
        deptId: deptIds,
      });
      if (result.code == 200) {
        this.asideNodeClick({});
        await this.getStaffList({ id: this.deptId }, pageNum, pageSize);
        this.$message({
          type: "success",
          message: "移除成功！",
        });
      }
    },
    handleHasPerms(e) {
      return hasPerms(e);
    }
  },
};
</script>

<style lang="scss" scoped>
.aside {
  height: 100vh;
  position: sticky;
  top: 0;
}
.organizational-structure {
  ::v-deep .el-main {
    padding: 0;
    padding-left: 12px;
  }
}
</style>
